import { render, staticRenderFns } from "./coda-product-edit.vue?vue&type=template&id=04fcf00a&scoped=true&"
import script from "./coda-product-edit.vue?vue&type=script&lang=js&"
export * from "./coda-product-edit.vue?vue&type=script&lang=js&"
import style0 from "./coda-product-edit.vue?vue&type=style&index=0&id=04fcf00a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04fcf00a",
  null
  
)

export default component.exports